<template>
  <div style="overflow: hidden" class="boxChart">
    <dv-loading v-if="Loading">Loading...</dv-loading>
    <dv-full-screen-container v-else>
      <div id="header-title">
        <dv-decoration-11 class="headerdecoration"
          >赣州经开区人才信息管理系统
        </dv-decoration-11>
      </div>
      <el-row :gutter="10">
        <el-col class="qucflater" :span="7"
          ><div class="grid-content bg-purple">
            <dv-border-box-11
              title="总数"
              :color="['#409EFF']"
              backgroundColor="#0d2a42"
            >
              <el-card class="box-card">
                <div
                  class="numBox"
                  style="overflow: hidden;padding: 25px 0 0 0;height: 25vh;"
                >
                  <el-row :gutter="12" class="cesNum">
                    <div v-for="(item, i) in DmountData" :key="i">
                      <el-col :span="24" v-if="i < 2">
                        <el-card shadow="never" :class="'noboder qiyerec' + i">
                          <h5 v-if="item.name">{{ item.name }}</h5>
                          <h2 v-text="item.account"></h2>
                        </el-card>
                      </el-col>
                    </div>
                  </el-row>
                  <el-row
                    :gutter="12"
                    class="cesNum"
                    style="width: 197px;float: right;position: absolute;top: 25px;right: 0; padding: 18px 0 0 0;"
                  >
                    <div v-for="(item, i) in DmountData" :key="i">
                      <el-col :span="24" v-if="i >= 2">
                        <el-card shadow="never" :class="'noboder'">
                          <h5 v-if="item.name">{{ item.name }}</h5>
                          <h2 v-text="item.account"></h2>
                        </el-card>
                      </el-col>
                    </div>
                  </el-row>
                </div>
              </el-card>
            </dv-border-box-11>
            <div class="hr-10"></div>
            <dv-border-box-11
              :color="['#409EFF']"
              title="乡贤"
              backgroundColor="#0d2a42"
            >
              <el-card class="box-card">
                <div class="buttons">
                  <el-button type="text" v-show="!Trade" @click="djsTrade()"
                    >切换地级市</el-button
                  >
                  <el-button type="text" v-show="Trade" @click="xzsTrade()"
                    >切换乡镇</el-button
                  >
                  <el-button
                    type="text"
                    @click="dialogTrade = true"
                    class="gengduo"
                    >更多>></el-button
                  >
                </div>

                <!-- <el-dialog title="乡贤" :visible.sync="dialogTrade" width="30%">
                  <el-table :data="tradeChart" style="width: 100%" border>
                    <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      width="50"
                    >
                    </el-table-column>
                    <el-table-column prop="name" label="地级市" width="180">
                    </el-table-column>
                    <el-table-column prop="amount" label="人数">
                    </el-table-column>
                  </el-table>
                </el-dialog> -->

                <div class="dialogTrade" v-show="dialogTrade">
                  <div class="Tradetitle">
                    乡贤
                    <el-button
                      type="text"
                      class="fr"
                      @click="dialogTrade = false"
                      icon="el-icon-close"
                      >关闭</el-button
                    >
                  </div>
                  <el-table :data="tradeChart" style="width: 100%" border>
                    <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      width="50"
                    >
                    </el-table-column>
                    <el-table-column prop="name" label="地级市" width="180">
                    </el-table-column>
                    <el-table-column prop="amount" label="人数">
                    </el-table-column>
                  </el-table>
                </div>

                <div v-show="Trade == true">
                  <div id="tradeChart" class="trade-chart"></div>
                </div>
                <div v-show="Trade == false">
                  <div
                    id="TradeChart"
                    class="trade-chart"
                    style="width:500px"
                  ></div>
                </div>
              </el-card>
            </dv-border-box-11>
            <div class="hr-10"></div>
            <dv-border-box-11
              :color="['#409EFF']"
              title="人才年龄分布"
              backgroundColor="#0d2a42"
            >
              <el-card class="box-card">
                <div id="ageChart" class="age-chart"></div> </el-card
            ></dv-border-box-11></div
        ></el-col>
        <el-col class="qucflater" :span="10"
          ><div class="grid-content bg-purple">
            <dv-border-box-11
              :color="['#409EFF']"
              title="人才类别"
              backgroundColor="#0d2a42"
            >
              <el-card class="box-card">
                <div id="typeChart" class="type-chart"></div>
                <!--                    <el-button type="text" class="logTab" @click="dialogTabl = true">详细数据</el-button>-->
                <el-button-group class="type-select">
                  <el-button type="text" size="mini" @click="type('')"
                    >全部</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="type('/count/area')"
                    >地域</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="type('/count/area,province:江西省')"
                    >江西</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="type('/count/school')"
                    >院校</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="type('/count/area,city:赣州市')"
                    >赣州</el-button
                  >
                </el-button-group>
                <!-- <el-select
                class="type-select"
                size="mini"
                placeholder="请选择"
                @change="type(typeChart)"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="地域" value="/count/area"></el-option>
                <el-option
                  label="江西"
                  value="/count/area,province:江西省"
                ></el-option>
                <el-option label="院校" value="/count/school"></el-option>
                <el-option
                  label="赣州"
                  value="/count/area,city:赣州市"
                ></el-option>
              </el-select> -->
              </el-card>
            </dv-border-box-11>

            <div class="hr-10"></div>
            <dv-border-box-10 backgroundColor="#0d2a42">
              <el-card class="box-card">
                <ul class="typeNum">
                  <li v-for="(item, k) in TalentType" :key="k">
                    {{ item.name }}：<span>{{ item.account }} 人</span>
                  </li>
                </ul>
              </el-card>
            </dv-border-box-10>
          </div></el-col
        >
        <el-col class="qucflater" :span="7"
          ><div class="grid-content bg-purple"></div>
          <dv-border-box-11
            :color="['#409EFF']"
            title="人才学历分布"
            backgroundColor="#0d2a42"
          >
            <el-card class="box-card">
              <div id="educationChart" class="trade-chart"></div>
            </el-card>
          </dv-border-box-11>
          <div class="hr-10"></div>
          <dv-border-box-11
            :color="['#409EFF']"
            title="产业人才分类"
            backgroundColor="#0d2a42"
          >
            <el-card class="box-card">
              <div id="genderChart" class="trade-chart"></div>
            </el-card>
          </dv-border-box-11>
          <div class="hr-10"></div>
          <dv-border-box-11
            :color="['#409EFF']"
            title="单位人数"
            backgroundColor="#0d2a42"
          >
            <el-card class="box-card">
              <div id="enterpriseChart" class="trade-chart"></div>
              <el-button
                type="text"
                class="logTab gengduo"
                @click="riseChar = true"
                >更多</el-button
              >

              <!-- <el-dialog :visible.sync="riseChar">
                <el-table
                  :data="enterpdadeData"
                  height="300"
                  border
                  style="width: 100%"
                >
                  <el-table-column prop="indx" label="序号" width="60">
                  </el-table-column>
                  <el-table-column prop="name" label="单位名称" width="220">
                  </el-table-column>
                  <el-table-column prop="account" label="单位人数">
                  </el-table-column>
                </el-table>
                
              </el-dialog> -->

              <div class="riseChar" v-show="riseChar">
                <dv-border-box-7>
                  <div class="riseChartit ">
                    单位人数
                    <el-button
                      type="text"
                      class="fr"
                      @click="riseChar = false"
                      icon="el-icon-close"
                      >关闭</el-button
                    >
                  </div>

                  <el-table
                    :data="enterpdadeData"
                    height="300"
                    border
                    style="width: 100%"
                  >
                    <el-table-column prop="indx" label="序号" width="60">
                    </el-table-column>
                    <el-table-column prop="name" label="单位名称" width="220">
                    </el-table-column>
                    <el-table-column prop="account" label="单位人数">
                    </el-table-column>
                  </el-table>
                </dv-border-box-7>
              </div>
            </el-card>
          </dv-border-box-11>
        </el-col>
      </el-row>
    </dv-full-screen-container>
  </div>
</template>

<script>
import configDate from "@/utils/config";
export default {
  data() {
    return {
      Loading: true,
      colorList: configDate.colorList,
      DmountData: [],
      TalentType: [],
      defaultData: [21],
      dataString: "21",

      ageChart: [],
      educationChart: [],
      tradeChart: [],
      Trade: true,
      genderChart: [],
      enterpriseChart: [],
      enterpdadeData: [],
      dialogTrade: false,
      dialogTabl: false,
      riseChar: false,
      TypeDates: "",
    };
  },

  methods: {
    color16() {
      //十六进制颜色随机
      var r = Math.floor(Math.random() * 256);
      var g = Math.floor(Math.random() * 256);
      var b = Math.floor(Math.random() * 256);
      var color = "#" + r.toString(16) + g.toString(16) + b.toString(16);
      return color;
    },
    sortBykey(ary, key) {
      return ary.sort(function(a, b) {
        let x = a[key];
        let y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    amount() {
      let sefl = this;
      this.$axios.post("/statistic", this.defaultData).then(function(res) {
        if (res.data.status === 200) {
          //sefl.DmountData = res.data.data
          res.data.data.forEach((item) => {
            if (item.name !== "账号总数") sefl.DmountData.push(item);
          });
        }
      });
    },
    /**
     *
     * @param DataPath Array
     */
    type(DataPath) {
      let sefl = this;
      if (this.isString(DataPath) && DataPath !== "") {
        //sefl.TypeDates = DataPath
        console.log(DataPath);
        sefl.TypeDates =
          DataPath == "/count/area"
            ? "province"
            : DataPath == "/count/area,province:江西省"
            ? "city"
            : DataPath == "/count/school"
            ? "schoolName"
            : DataPath == "/count/area,city:赣州市"
            ? "county"
            : "";
        let sscshu = DataPath.split(",");
        if (sscshu.length == 2) {
          let ke = sscshu[1].split(":")[0];
          let val = sscshu[1].split(":")[1];
          this.$axios
            .post(
              sscshu[0] + "?" + [ke] + "=" + val + "&status=" + this.dataString
            )
            .then(function(res) {
              if (res.data.status === 200) {
                let dataNum = [];
                let dataName = [];
                let paxhou = sefl.sortBykey(res.data.data, "account");
                let DataNew = paxhou.reverse();
                DataNew.forEach(function(item, i) {
                  dataName.push(item.name);
                  dataNum.push(item.account);
                });
                sefl.TalentType = DataNew;
                sefl.TypeColumnar(dataName, dataNum);
              }
            });
        } else {
          console.log(DataPath === "/count/school");
          if (DataPath === "/count/school") {
            this.$axios
              .post("/count/school?status=" + this.dataString)
              .then(function(res) {
                if (res.data.status === 200) {
                  let dataNum = [];
                  let dataName = [];
                  let paxhou = sefl.sortBykey(res.data.data, "account");
                  let DataNew = paxhou.reverse();
                  DataNew.forEach(function(item, i) {
                    dataName.push(item.name);
                    dataNum.push(item.account);
                  });
                  sefl.TalentType = DataNew;
                  sefl.TypeColumnar(dataName, dataNum);
                }
              });
          } else {
            this.$axios
              .post("/count/area?status=" + this.dataString)
              .then(function(res) {
                if (res.data.status === 200) {
                  let dataNum = [];
                  let dataName = [];
                  let paxhou = sefl.sortBykey(res.data.data, "account");
                  let DataNew = paxhou.reverse();
                  DataNew.forEach(function(item, i) {
                    dataName.push(item.name);
                    dataNum.push(item.account);
                  });
                  sefl.TalentType = DataNew;
                  sefl.TypeColumnar(dataName, dataNum);
                }
              });
          }
        }
      } else {
        this.gender();
        this.age();
        this.education();
        this.TypeDates = "";
        this.$axios
          .post("/statistic/talent/type", this.defaultData)
          .then(function(res) {
            let dataNum = [];
            let dataName = [];
            if (res.data.status === 200) {
              /**
               * 大小排序
               */
              // let paxhou = sefl.sortBykey(res.data.data, "account");
              // let DataNew = paxhou.reverse();
              // DataNew.forEach(function(item, i) {
              //   dataName.push(item.name);
              //   dataNum.push(item.account);
              // });
              // sefl.TalentType = DataNew;
              /**
               * 类型排序
               */
              let DataNew = [];
              configDate.typesTalents.forEach(function(item, i) {
                //dataNum.push(item.account);
                res.data.data.forEach(function(its, index) {
                  if (item.name == its.name) {
                    dataName.push(item.name);
                    dataNum.push(its.account);
                    DataNew.push({
                      name: item.name,
                      account: its.account,
                    });
                  }
                });
              });
              sefl.TalentType = DataNew;
              sefl.TypeColumnar(dataName, dataNum);
            }
          });
      }
    },
    //人才类别
    TypeColumnar(dataName, dataNum) {
      //console.log(typedeta)
      let sefl = this;

      // 基于准备好的dom，初始化echarts实例
      let typeChart = sefl.$echarts.init(document.getElementById("typeChart"));
      typeChart.off("click");
      typeChart.on("click", function(params) {
        //console.log(sefl.TypeDates)
        sefl.gender(params.name, sefl.TypeDates);
        sefl.age(params.name, sefl.TypeDates);
        sefl.education(params.name, sefl.TypeDates);
      });

      // 绘制图表
      typeChart.setOption({
        // title: {
        //   text: "人才类别",
        //   left: 20,
        //   top: 16,
        //   textStyle: {
        //     color: "#1091ed",
        //     fontSize: 16,
        //   },
        // },
        color: "#fe7215",
        tooltip: {},
        bar: {
          barCategoryGap: "40%",
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "30%",
          top: "15%",
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            xAxisIndex: [0],
            startValue: 7,
            endValue: 7,
            left: "5%",
            bottom: 15,
            start: 0,
          },
        ],
        xAxis: {
          data: dataName,
          axisLine: {
            // 坐标轴线
            show: true, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: "#1091ed",
              type: "solid",
              width: 2,
            },
          },

          axisLabel: {
            interval: 0,
            rotate: 45,
            formatter: function(value) {
              if (value.length > 4) {
                return value.substring(0, 8) + "..";
              } else {
                return value;
                //return value.split('').join('\n');
              }

              //return value.split('').join('\n');
            },
          },
        },
        yAxis: {
          axisLine: {
            // 坐标轴线
            show: true, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: "#1091ed",
              width: 2,
              type: "solid",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#315070"],
              width: 1,
              type: "solid",
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 30,
            data: dataNum,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 16,
                  },
                  formatter: function(v) {
                    let val = v.data;
                    return val + "人";
                  },
                },
                //每根柱子颜色设置
                // color: this.color16(),
                color: function(params) {
                  return sefl.colorList[params.dataIndex];
                },
                barBorderRadius: 5,
              },
            },
          },
        ],
      });
    },
    //人才类别 地图
    TypeMap() {
      let sefl = this;
    },

    age(Data = 1, Type = "") {
      let sefl = this;
      // let data = {
      //     compares:[
      //         {max: 20,min: 0},
      //         {max: 30,min: 20},
      //         {max: 45,min: 30},
      //         {max: 60,min: 45},
      //     ],
      //     statuses:[11, 20, 21]
      // }
      let compares = [
        { max: 20, min: 0 },
        { max: 30, min: 20 },
        { max: 45, min: 30 },
        { max: 60, min: 45 },
        { max: 120, min: 60 },
      ];
      if (Type != "") {
        this.$axios
          .post(
            "/count/age?" + Type + "=" + Data + "&status=" + this.dataString,
            compares
          )
          .then(function(res) {
            let dataNum = [];
            let dataName = [];
            console.log(res.data);
            if (res.data.status === 200) {
              let paxhou = sefl.sortBykey(res.data.data, "account");
              let DataNew = paxhou.reverse();
              DataNew.forEach(function(item) {
                dataName.push(item.name + "岁");
                dataNum.push({
                  value: item.account,
                  name: item.name + "岁",
                });
              });
              let ageChart = sefl.$echarts.init(
                document.getElementById("ageChart")
              );
              ageChart.setOption({
                // title: {
                //   text: "人才年龄分布",
                //   right: 20,
                //   top: 16,
                //   textStyle: {
                //     color: "#1091ed",
                //     fontSize: 16,
                //   },
                // },
                tooltip: {
                  trigger: "item",
                  formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                  left: 0,
                  bottom: 0,
                  top: "bottom",
                  data: dataName,
                  textStyle: {
                    color: "#fff",
                  },
                },

                series: [
                  {
                    name: "年龄",
                    type: "pie",
                    radius: "40%",
                    center: ["40%", "50%"],
                    data: dataNum,

                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          formatter: "{c} 人({d}%)",
                          textStyle: {
                            //数值样式
                            color: "#fff",
                            fontSize: 16,
                          },
                        },
                        labelLine: { show: true },
                        //每根柱子颜色设置
                        color: function(params) {
                          return sefl.colorList[params.dataIndex];
                        },
                      },
                    },
                  },
                ],
              });
            }
          });
      } else {
        this.$axios
          .post("/count/age?status=" + this.dataString, compares)
          .then(function(res) {
            let dataNum = [];
            let dataName = [];
            console.log(res.data);
            if (res.data.status === 200) {
              let paxhou = sefl.sortBykey(res.data.data, "account");
              let DataNew = paxhou.reverse();
              DataNew.forEach(function(item) {
                dataName.push(item.name + "岁");
                dataNum.push({
                  value: item.account,
                  name: item.name + "岁",
                });
              });
              let ageChart = sefl.$echarts.init(
                document.getElementById("ageChart")
              );
              ageChart.setOption({
                // title: {
                //   text: "人才年龄分布",
                //   right: 20,
                //   top: 16,
                //   textStyle: {
                //     color: "#1091ed",
                //     fontSize: 16,
                //   },
                // },
                tooltip: {
                  trigger: "item",
                  formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                  left: 0,
                  bottom: 0,
                  top: "bottom",
                  data: dataName,
                  textStyle: {
                    color: "#fff",
                  },
                },
                grid: {
                  top: 0,
                },
                series: [
                  {
                    name: "年龄",
                    type: "pie",
                    radius: "40%",
                    center: ["40%", "50%"],
                    data: dataNum,

                    itemStyle: {
                      normal: {
                        //每根柱子颜色设置
                        color: function(params) {
                          return sefl.colorList[params.dataIndex];
                        },
                        label: {
                          show: true,
                          formatter: "{c} 人({d}%)",
                          textStyle: {
                            //数值样式
                            color: "#fff",
                            fontSize: 16,
                          },
                        },
                        labelLine: { show: true },
                      },
                    },
                  },
                ],
              });
            }
          });
      }
    },
    /**
     *
     * @param defaultData Array
     */
    education(Data = 1, Type = "") {
      let sefl = this;
      if (Type !== "") {
        this.$axios
          .post(
            "/count/education?" +
              Type +
              "=" +
              Data +
              "&status=" +
              this.dataString
          )
          .then(function(res) {
            let dataNum = [];
            let dataName = [];
            let paxhou = sefl.sortBykey(res.data.data, "account");
            let DataNew = paxhou.reverse();
            DataNew.forEach(function(item) {
              dataName.push(item.name);
              dataNum.push({
                value: item.account,
                name: item.name,
              });
            });
            let educationChart = sefl.$echarts.init(
              document.getElementById("educationChart")
            );
            educationChart.setOption({
              // title: {
              //   text: "人才学历分布",
              //   left: 20,
              //   top: 16,
              //   textStyle: {
              //     color: "#1091ed",
              //     fontSize: 16,
              //   },
              // },
              tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)",
              },
              legend: {
                left: 0,
                bottom: 0,
                top: "bottom",
                data: dataName,
                textStyle: {
                  color: "#fff",
                },
              },

              series: [
                {
                  name: "学历",
                  type: "pie",
                  radius: ["25%", "40%"],
                  center: ["55%", "55%"],
                  //roseType: 'area',
                  data: dataNum,
                  itemStyle: {
                    normal: {
                      label: {
                        show: true,
                        formatter: "{c} 人({d}%)",
                        textStyle: {
                          //数值样式
                          color: "#fff",
                          fontSize: 16,
                        },
                      },
                      labelLine: { show: true },
                      color: function(params) {
                        return sefl.colorList[params.dataIndex];
                      },
                    },
                  },
                },
              ],
            });
          });
      } else {
        this.$axios
          .post("/count/education?status=" + this.dataString)
          .then(function(res) {
            let dataNum = [];
            let dataName = [];
            let paxhou = sefl.sortBykey(res.data.data, "account");
            let DataNew = paxhou.reverse();
            DataNew.forEach(function(item) {
              dataName.push(item.name);
              dataNum.push({
                value: item.account,
                name: item.name,
              });
            });

            let educationChart = sefl.$echarts.init(
              document.getElementById("educationChart")
            );

            educationChart.setOption({
              // title: {
              //   text: "人才学历分布",
              //   left: 20,
              //   top: 16,
              //   textStyle: {
              //     color: "#1091ed",
              //     fontSize: 16,
              //   },
              // },
              tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)",
              },
              legend: {
                left: 0,
                bottom: 0,
                top: "bottom",
                data: dataName,
                textStyle: {
                  color: "#fff",
                },
              },

              series: [
                {
                  name: "学历",
                  type: "pie",
                  radius: ["25%", "40%"],
                  //roseType: 'area',
                  center: ["55%", "55%"],
                  data: dataNum,
                  itemStyle: {
                    normal: {
                      label: {
                        show: true,
                        formatter: "{c} 人({d}%)",
                        textStyle: {
                          //数值样式
                          color: "#fff",
                          fontSize: 16,
                        },
                      },
                      labelLine: { show: true },
                      //每根柱子颜色设置
                      color: function(params) {
                        return sefl.colorList[params.dataIndex];
                      },
                    },
                  },
                },
              ],
            });
          });
      }
    },
    /**
     * @param defaultData Array
     */
    trade() {
      let sefl = this;
      this.$axios
        .get("/worthy/count?status=" + this.dataString)
        .then(function(res) {
          console.log(res.data.data);
          let dataNum = [];
          let dataName = [];
          let paxhou = sefl.sortBykey(res.data.data, "amount");
          let DataNew = paxhou.reverse();
          sefl.tradeChart = DataNew;
          DataNew.forEach(function(item, i) {
            console.log(i + 1, item.name);
            if (i < 3) {
              dataName.push(item.name);
              dataNum.push(item.amount);
            }
          });
          console.log(dataNum.reverse(), dataName.reverse());
          let tradeChart = sefl.$echarts.init(
            document.getElementById("tradeChart")
          );
          tradeChart.setOption({
            tooltip: {},
            xAxis: {
              axisLine: {
                // 坐标轴线
                show: true, // 默认显示，属性show控制显示与否
                lineStyle: {
                  // 属性lineStyle控制线条样式
                  color: "#1091ed",
                  width: 2,
                  type: "solid",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: ["#315070"],
                  width: 1,
                  type: "solid",
                },
              },
            },
            grid: {
              left: "15%",
              bottom: "10%",
            },
            yAxis: {
              data: dataName,
              axisLine: {
                // 坐标轴线
                show: true, // 默认显示，属性show控制显示与否
                lineStyle: {
                  // 属性lineStyle控制线条样式
                  color: "#1091ed",
                  width: 2,
                  type: "solid",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: ["#315070"],
                  width: 1,
                  type: "solid",
                },
              },
            },
            series: [
              {
                type: "bar",
                barWidth: 25,
                itemStyle: {
                  normal: {
                    //每根柱子颜色设置
                    color: function(params) {
                      return sefl.colorList[params.dataIndex];
                    },
                    barBorderRadius: 5,
                    label: {
                      show: true, //开启显示
                      position: "right", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "#fff",
                        fontSize: 16,
                      },
                      formatter: function(v) {
                        let val = v.data;
                        return val + "人";
                      },
                    },
                  },
                },

                data: dataNum,
              },
            ],
          });
        });
    },
    xztrade() {
      let sefl = this;
      this.$axios.get("/worthy/manager/page").then(function(res) {
        let nedDate = [];
        res.data.data.records.forEach((item) => {
          nedDate.push({
            amount: item.worthyCount,
            name: item.township,
          });
        });

        let dataNum = [];
        let dataName = [];
        let paxhou = sefl.sortBykey(nedDate, "amount");
        let DataNew = paxhou.reverse();
        sefl.tradeChart = DataNew;
        DataNew.forEach(function(item, i) {
          console.log(item);
          if (i < 6) {
            dataName.push(item.name);
            dataNum.push(item.amount);
          }
        });

        let TradeChart = sefl.$echarts.init(
          document.getElementById("TradeChart")
        );
        TradeChart.setOption({
          xAxis: {
            data: dataName,
            axisLine: {
              // 坐标轴线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "#1091ed",
                width: 2,
                type: "solid",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#315070"],
                width: 1,
                type: "solid",
              },
            },
          },
          grid: {
            left: "15%",
            bottom: "10%",
          },
          yAxis: {
            axisLine: {
              // 坐标轴线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "#1091ed",
                width: 2,
                type: "solid",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#315070"],
                width: 1,
                type: "solid",
              },
            },
          },
          series: [
            {
              type: "bar",
              barWidth: 30,
              barWidth: 25,
              itemStyle: {
                normal: {
                  //每根柱子颜色设置
                  color: function(params) {
                    return sefl.colorList[params.dataIndex];
                  },
                  barBorderRadius: 5,
                  label: {
                    show: true, //开启显示
                    position: "right", //在上方显示
                    textStyle: {
                      //数值样式
                      color: "#fff",
                      fontSize: 16,
                    },
                    formatter: function(v) {
                      let val = v.data;
                      return val + "人";
                    },
                  },
                },
              },

              data: dataNum,
            },
          ],
        });
      });
    },
    djsTrade() {
      this.Trade = true;
      this.trade();
    },
    xzsTrade() {
      this.Trade = false;
      this.xztrade();
    },
    /**
     *
     * @param defaultData Array
     */
    gender(Data = 1, Type = "") {
      let sefl = this;

      if (Type !== "") {
        console.log(Data, Type);
        this.$axios
          .post(
            "/count/industry?" +
              Type +
              "=" +
              Data +
              "&status=" +
              this.dataString
          )
          .then(function(res) {
            let dataNum = [];
            let dataName = [];
            let paxhou = sefl.sortBykey(res.data.data, "account");
            let DataNew = paxhou.reverse();
            DataNew.forEach(function(item) {
              dataName.push(item.name);
              dataNum.push(item.account);
            });
            let genderChart = sefl.$echarts.init(
              document.getElementById("genderChart")
            );
            genderChart.setOption({
              // title: {
              //   text: "产业人才分类",
              //   left: 0,
              //   top: 0,
              //   textStyle: {
              //     color: "#1091ed",
              //     fontSize: 16,
              //   },
              // },
              tooltip: {},
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  xAxisIndex: [0],
                  startValue: 7,
                  endValue: 7,
                  left: "5%",
                  bottom: 0,
                  start: 0,
                },
              ],
              xAxis: {
                data: dataName,
                axisLine: {
                  // 坐标轴线
                  show: true, // 默认显示，属性show控制显示与否
                  lineStyle: {
                    // 属性lineStyle控制线条样式
                    color: "#1091ed",
                    width: 2,
                    type: "solid",
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ["#315070"],
                    width: 1,
                    type: "solid",
                  },
                },
                axisLabel: {
                  interval: 0,
                  formatter: function(value) {
                    if (value.length > 3) {
                      return value.substring(0, 3) + "..";
                    } else {
                      return value;
                    }
                    console.log(value.substring(0, 5));
                    //return value.split('').join('\n');
                  },
                },
              },
              grid: {
                top: "20%",
                left: "5%",
                right: "5%",
                bottom: "25%",
              },
              yAxis: {
                axisLine: {
                  // 坐标轴线
                  show: true, // 默认显示，属性show控制显示与否
                  lineStyle: {
                    // 属性lineStyle控制线条样式
                    color: "#1091ed",
                    width: 2,
                    type: "solid",
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ["#315070"],
                    width: 1,
                    type: "solid",
                  },
                },
              },
              series: [
                {
                  type: "bar",
                  barWidth: 30,
                  itemStyle: {
                    normal: {
                      //每根柱子颜色设置
                      color: function(params) {
                        return sefl.colorList[params.dataIndex];
                      },
                      barBorderRadius: 5,
                      label: {
                        show: true,
                        formatter: "{c} 人",
                        position: "top", //在上方显示
                        textStyle: {
                          //数值样式
                          color: "#fff",
                          fontSize: 16,
                        },
                      },
                    },
                  },
                  data: dataNum,
                },
              ],
            });
          });
      } else {
        this.$axios
          .post("/count/industry?status=" + this.dataString)
          .then(function(res) {
            let dataNum = [];
            let dataName = [];
            let paxhou = sefl.sortBykey(res.data.data, "account");
            let DataNew = paxhou.reverse();
            DataNew.forEach(function(item) {
              dataName.push(item.name);
              dataNum.push(item.account);
            });
            let genderChart = sefl.$echarts.init(
              document.getElementById("genderChart")
            );
            genderChart.setOption({
              // title: {
              //   text: "产业人才分类",
              //   left: 0,
              //   top: 0,
              //   textStyle: {
              //     color: "#1091ed",
              //     fontSize: 16,
              //   },
              // },
              tooltip: {},
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  xAxisIndex: [0],
                  startValue: 7,
                  endValue: 7,
                  left: "5%",
                  bottom: 0,
                  start: 0,
                },
              ],
              xAxis: {
                data: dataName,
                axisLine: {
                  // 坐标轴线
                  show: true, // 默认显示，属性show控制显示与否
                  lineStyle: {
                    // 属性lineStyle控制线条样式
                    color: "#1091ed",
                    width: 2,
                    type: "solid",
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ["#315070"],
                    width: 1,
                    type: "solid",
                  },
                },
                axisLabel: {
                  interval: 0,
                  formatter: function(value) {
                    if (value.length > 3) {
                      return value.substring(0, 3) + "..";
                    } else {
                      return value;
                    }
                    console.log(value.substring(0, 5));
                    //return value.split('').join('\n');
                  },
                },
              },
              grid: {
                top: "20%",
                left: "5%",
                right: "5%",
                bottom: "25%",
              },
              yAxis: {
                axisLine: {
                  // 坐标轴线
                  show: true, // 默认显示，属性show控制显示与否
                  lineStyle: {
                    // 属性lineStyle控制线条样式
                    color: "#1091ed",
                    width: 2,
                    type: "solid",
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ["#315070"],
                    width: 1,
                    type: "solid",
                  },
                },
              },
              series: [
                {
                  type: "bar",
                  barWidth: 30,
                  itemStyle: {
                    normal: {
                      //每根柱子颜色设置
                      color: function(params) {
                        return sefl.colorList[params.dataIndex];
                      },
                      barBorderRadius: 5,
                      label: {
                        show: true,
                        formatter: "{c} 人",
                        position: "top", //在上方显示
                        textStyle: {
                          //数值样式
                          color: "#fff",
                          fontSize: 16,
                        },
                      },
                      labelLine: { show: true },
                    },
                  },
                  data: dataNum,
                },
              ],
            });
          });
      }
    },
    /**
     *
     * @param defaultData Array
     */
    enterprise(defaultData) {
      let sefl = this;
      this.$axios
        .post("/statistic/talent/enterprise", defaultData)
        .then(function(res) {
          let dataNum = [];
          let dataName = [];
          let paxhou = sefl.sortBykey(res.data.data, "account");
          let DataNew = paxhou.reverse();
          DataNew.forEach(function(item, i) {
            sefl.enterpdadeData.push({
              indx: i + 1,
              name: item.name,
              account: item.account + " (人)",
            });
            if (i <= 7) {
              dataName.push(item.name);
              dataNum.push(item.account);
              console.log(i);
            }
          });
          let enterpriseChart = sefl.$echarts.init(
            document.getElementById("enterpriseChart")
          );

          enterpriseChart.setOption({
            // title: {
            //   text: "单位人数",
            //   left: 0,
            //   top: 0,
            //   textStyle: {
            //     color: "#1091ed",
            //     fontSize: 16,
            //   },
            // },
            grid: {
              top: "20%",
              left: "10%",
              right: "5%",
              bottom: "20%",
            },
            tooltip: {},
            xAxis: {
              type: "category",
              boundaryGap: false,
              axisLine: {
                // 坐标轴线
                show: true, // 默认显示，属性show控制显示与否
                lineStyle: {
                  // 属性lineStyle控制线条样式
                  color: "#1091ed",
                  width: 2,
                  type: "solid",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: ["#315070"],
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                interval: 0,
                rotate: 45,
                formatter: function(value) {
                  if (value.length > 4) {
                    return value.substring(0, 4) + "..";
                  } else {
                    return value;
                  }
                  console.log(value.substring(0, 5));
                  //return value.split('').join('\n');
                },
              },
              data: dataName,
            },
            yAxis: {
              axisLine: {
                // 坐标轴线
                show: true, // 默认显示，属性show控制显示与否
                lineStyle: {
                  // 属性lineStyle控制线条样式
                  color: "#1091ed",
                  width: 2,
                  type: "solid",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: ["#315070"],
                  width: 1,
                  type: "solid",
                },
              },
            },
            series: [
              {
                data: dataNum,
                type: "line",
                areaStyle: {},
                itemStyle: {
                  normal: {
                    color: "#b4a6fa",
                    label: {
                      show: true,
                      formatter: "{c} 人",
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "#fff",
                        fontSize: 16,
                      },
                    },
                  },
                },
              },
            ],
          });
        });
    },
    prev() {
      this.$router.go(-1);
    },
    start() {
      this.rotate = !this.rotate;
    },
    isString(str) {
      return typeof str == "string" && str.constructor == String;
    },
  },
  mounted() {
    setTimeout(() => {
      this.Loading = false;
      this.amount(); //数据总览
      this.age();
      this.type(this.defaultData);
      // this.education(this.defaultData);
      this.xztrade();
      this.trade(this.defaultData);

      this.gender();
      this.enterprise(this.defaultData); //单位人数
    }, 2000);
  },
};
</script>

<style scoped lang="less">
.box-card {
  position: relative;
}
.el-card {
  background: none;
  border: 0;
}
.fabj {
  border: 1px solid #1091ed;
  box-shadow: 0 0 6px #ddd;
  overflow: hidden;
}
.cesNum {
  width: 100%;
  height: 25vh;
  border-radius: 20px;
  float: left;
  margin: 0 5px;
}

/*.zsnum1 {*/
/*    background: #fff url("~@/assets/zsnum1.png") no-repeat top right;*/
/*}*/

/*.zsnum2 {*/
/*    background: #fff url("~@/assets/zsnum2.png") no-repeat top center;*/
/*}*/

/*.zsnum3 {*/
/*    background: #fff url("~@/assets/zsnum3.png") no-repeat top center;*/
/*}*/

/*.zsnum4 {*/
/*    background: #fff url("~@/assets/zsnum4.png") no-repeat top center;*/
/*}*/

/*.cesNum h1 {*/
/*    width: 80%;*/
/*    margin: 0 auto;*/
/*    text-align: left;*/
/*    line-height: 65px;*/
/*    color: #fff;*/
/*}*/
.el-card.noboder {
  border-top: 0;
  border-bottom: 0;
  border-radius: 0;
  h4 {
    font-weight: 400;
  }
}
/*.cesNum h5 {*/
/*    width: 80%;*/
/*    margin: 0 auto;*/
/*    text-align: left;*/
/*    color: #fff;*/
/*}*/

.boxChart {
  /*background-color:#c00*/
  height: 100vh;
  background: #060e1b;
  background-size: 100%;
}
.buttons {
  position: absolute;
  right: 5px;
  z-index: 999;
  top: 48px;
  button {
    padding: 0 10px;
    cursor: pointer;
    color: #fff;
  }
}
.enterpriseChartpro {
  position: fixed;
  z-index: 999;
  width: 90%;
  height: 70%;
  top: 45%;
  margin: -15% 0 0 -30%;
  left: 35%;
  background: #ddd;
}
.qiyerec0,
.qiyerec1 {
  text-align: left;
  padding: 0 0 0 95px;
  border: 0;
  h5 {
    font-weight: 400;
    color: #fff;
  }
  h2 {
    color: #fff;
  }
}

.el-card__body {
  h2,
  h5,
  h4 {
    color: #fff;
  }
}
.qiyerec0 {
  background: url("~@/assets/qyere0.png") no-repeat left center;
}
.qiyerec1 {
  background: url("~@/assets/qyere1.png") no-repeat left center;
}
#header-title {
  width: 100%;
  height: 60px;
}

.headerdecoration {
  width: 500px;
  height: 60px;
  color: #fff;
  margin: 0 auto;
  font-size: 26px;
}
.header-title-text {
  margin: 16px 0 0 24px;
  color: #ffffff;
}

.header-name {
  color: #ffffff;
  padding-top: 0px;
  font-size: 12px;
}

.header-account {
  color: #ffffff;
  padding-top: 8px;
  font-size: 26px;
}

.el-icon-s-data {
  font-size: 30px;
  padding-right: 8px;
}

.type-chart {
  width: 100%;
  float: right;
  height: 57vh;
  display: inline-block;
}

.typeNum {
  padding: 0;
  overflow: auto;
  height: 26vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}

.typeNum li {
  text-align: left;
  font-size: 15px;
  line-height: 40px;
  font-weight: 600;
  color: #33acf1;
  border-bottom: 1px #adadad solid;
  width: 48%;
  box-shadow: #c3c3c3 0 0 3px;
  padding: 0 0 0 10px;
  margin: 6px 6px 0;
  border-radius: 5px;
  span {
    float: right;
    width: 45px;
  }
}

.age-chart {
  width: 100%;
  height: 26vh;

  display: inline-block;
}

/*.education-chart {*/
/*    width: 100%;*/
/*    height: 386px;*/
/*    margin-top: 8px;*/
/*    !*background-color: #ffffff;*!*/
/*    background:url("~@/assets/mkleft.png") no-repeat top left;*/
/*    display: inline-block;*/
/*}*/
.DChartbox {
  background: url("~@/assets/dmkleft.png") no-repeat 20px 0;
}

.Chartbox {
  overflow: hidden;
  background: url("~@/assets/mkright.png") no-repeat right top;
}

.trade-chart {
  width: 100%;
  height: 25vh;
  display: inline-block;
}

.type-select,
.logTab {
  position: absolute;
  right: 5%;
  top: 45px;
  color: #fff;
}
.type-select {
  top: 50px;
  button {
    padding: 0 10px;
  }
}
.riseChar,
.dialogTrade {
  background: #fff;
  position: fixed;
  top: 27%;
  left: 36%;
  z-index: 9999;
  width: 600px;
}
.dialogTrade .Tradetitle,
.riseChar .riseChartit {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
}
@import "../../../styles/common_comtril.less";
</style>
